.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  margin-right: auto;
  overflow: hidden;

  .wkn {
    display: block;
  }

  svg > * {
    body.showMenu & {
      @include responsive($menuBreakpoint, max) {
        fill: $white;
      }
    }

    html.--dark & {
      fill: $white;
    }
  }

  .Intro & {
    margin-left: auto;

    svg > * {
      fill: $white;
    }
  }
}