.Icon {
  @include imgRatio(1, 1);
  position: relative;

  svg {
    @include cover();
  }

  &.--arrow{
    @include imgRatio(18, 19);
    @include svg-limited(18);

    svg > *{
      stroke: $white;
    }
  }

  &.--chevron{
    @include imgRatio(5, 8);
    @include svg-limited(5);

    svg {
      overflow: visible;

      > *{
        stroke: $white;
      }
    }
  }

  &.--arrow-external{
    @include imgRatio(13, 13);
    @include svg-limited(13);

    svg > *{
      stroke: $white;
    }
  }

  &.--arrow-download{
    @include imgRatio(19, 18);
    @include svg-limited(19);

    svg > *{
      stroke: $white;
    }
  }

  &.--watermark{
    @include imgRatio(443, 450);
    @include svg-limited(443);

    svg > * {
      fill: $white;
    }
  }

  &.--search{
    @include imgRatio(28, 28);
    @include svg-limited(28);

    svg > *{
      stroke: $white;
    }
  }

  &.--burger{
    @include imgRatio(11, 9);
    @include svg-limited(11);

    svg > *{
      fill: $white;
    }
  }
}
