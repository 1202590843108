.PageHeaderCity{
  .container {
    .titles{
      @include flex(center, center);
      flex-direction: column;
      
      @include responsive($tabletSmall){
        max-width: get-vw(610);
        margin: 0 auto;
      }

      @include responsive($deskXXL){
        max-width: 610px;
      }

      h1{
        color: $white;
        max-width: 16em;
        line-height: 0.9;
      }

      .city{
        font: 900 20px $main-font;
        @include font-limited(40);
        line-height: 1;
        color: $black;
        border-radius: 13px;
        border: 0.26em solid $red;
        outline: 0.13em solid $white;
        border-radius: 13px;
        transform: rotate(-1.76deg);
        padding: 0.5em 0.5em 0.3em;
        text-transform: uppercase;
        // margin-top: -0.1em;
        // max-width: 85%;
      }
    } 

    > .Link{
      font: 600 14px $main-font;
      @include font-limited(14);
      @include flex(center, center, inline-flex);
      gap: 0.5em;
      margin-top: 2em;
      position: relative;
      z-index: 1;

      &:hover{
        .Icon.--chevron{
          transform: translate(50%, 0);
        }
      }

      .Icon.--chevron{
        body:not(.--animating) &{
          transition: transform 500ms $ease 100ms;
        }
      }
    }
  }

  & + .Banner{
    margin-top: 0;
  }
}
