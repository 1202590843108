.FormSearchCommune{
  width: 100%;
  box-shadow: 0px 50px 80px rgba($black, 0.1);
  padding: 2.5em 2em;
  
  @include responsive($tabletSmall){
    max-width: get-vw(1020);
    margin: 0 auto;
    padding: 3.5em 4em;
  }

  @include responsive($deskXXL){
    max-width: 1020px;
  }

  .SearchCommune &{
    padding: 2em;
  }

  .PageHeaderHome &{
    @include responsive($tabletSmall){
      transform: translate(0, 30%);
    }
  }

  label{
    color: $black;
    display: block;
    margin-bottom: 1em;
  }

  > .autocomplete-container{
    position: relative;

    input{
      border: 1px solid rgba($black, 0.1);
      border-radius: 20em;
      width: 100%;
      padding: 1.2em 5em 1em 1.5em;
      color: $black;

      @include responsive($mobile, max){
        font-size: 4vw;
      }

      &::placeholder{
        opacity: 0.4;
      }
    }

    button{
      @include flex(center, center);
      position: absolute;
      top: 50%; right: 0;
      transform: translate(-20%, -50%);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: $red;
      pointer-events: none;

      @include responsive($tabletSmall){
        height: get-vw(60);
        width: get-vw(60);
      }

      @include responsive($deskXXL){
        height: 60px;
        width: 60px;
      }

      @include noTouchHover() {
        transform: translate(-20%, -50%) scale(0.85);

        .Icon.--search{
          transform: rotate(-6.5deg) scale(1.05);
        }
      }

      &, 
      .Icon.--search {
        body:not(.--animating) &{
          transition: transform 500ms $ease;
        }
      }

      .Icon.--search{
        @include responsive($tabletSmall, max){
          min-width: 20px;
        }
      }
    }

    .autocomplete-items{
      font: 500 15px $main-font;
      @include font-limited(15);
      display: grid;
      width: 100%;
      max-width: 350px;
      color: $black;
      box-shadow: 0px 50px 80px rgba($black, 0.1);
      max-height: 130px;
      position: absolute;
      top: 100%; left: 0;
    
      border: 1px solid #e9e9e9;
      margin-top: 1em;
      margin-left: 1em;
      padding: 0.5em;
      
      &.--no-result{
        padding: 1.2em 1em 1em;
      }

      &:not(.--no-result):not(:empty){
        overflow-y: scroll;
      }

      &:empty{
        display: none;
      }

      @include responsive($tabletSmall, max){
        margin-left: 0;
      }
    
      > a{
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 10px;
        padding: 0.75em 0.5em 0.5em;

        body:not(.--animating) &{
          transition: background-color 350ms $ease;
        }

        @include noTouchHover() {
          background-color: $grey-l;
        }
      }
    }
  }
}