.CityChangements{
  background: #F3F3F3;

  @include responsive($tabletSmall, max){
    overflow: clip;
  }

  h2{
    text-align: center;
    
    @include responsive($tabletSmall){
      max-width: get-vw(900);
      margin: 0 auto;
    }

    @include responsive($deskXXL){
      max-width: 900px;
    }
  }

  .grid{
    margin-top: 5em;
    padding-bottom: 5em;
    display: grid;
    grid-gap: 1em;
  }

  .no-changement{
    @include flex(center, center);
    flex-direction: column;
    text-align: center;
    background: $green-light;
    position: relative;
    padding: 4.5em 2em;

    @include responsive($tabletSmall){
      padding: 7em 12em;
    }

    > *:not(.Icon.--watermark){
      position: relative;
      z-index: 1;
    }

    .ribbon{
      background-color: #003C27;
      transform: rotate(2deg);
      margin-bottom: 1em;
    }

    h2{
      color: #003C27;

      strong{
        color: $white;
      }
    }

    .Icon.--watermark{
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      opacity: 0.1;
      pointer-events: none;

      @include responsive($tabletSmall, max){
        min-width: 15em;
      }
    }
  }
}
