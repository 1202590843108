.Intermodality{
  background: #A4D6D4;
  color: #1B1B3A;

  .container{
    @include flex(flex-start);
    gap: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .wrapper{
      max-width: 540px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(540);
      }

      h2{
        margin-bottom: 0.7em;
      }
      
      .content{
        .chapo{
          color: $cyan;
          margin-bottom: 1em;
        }
      }
    }

    .visual__container{
      @include imgRatio(580, 680);

      @include responsive($tabletSmall){
        max-width: get-vw(580);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 580px;
      }
    }
  }
}
