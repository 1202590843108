.NavToggle {
  background-color: #530B28;
  z-index: 10;
  margin-top: 0;
  justify-self: end;
  overflow: hidden;
  pointer-events: all;
  
  body:not(.--animating) &{
    transition: transform 450ms $ease,
                background-color 450ms $ease,
                padding 450ms $ease;
  }

  @include responsive($menuBreakpoint) {
    display: none;
  }

  body.--show-menu &{
    padding-right: 2em;
    background-color: $red;
  }

  .labels{
    position: relative;

    body.--show-menu &{
      > span{
        &:nth-child(1){
          transform: translate(0, -100%);
          opacity: 0;
        }

        &:nth-child(2){
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }

    > span{
      body:not(.--animating) &{
        transition: opacity 500ms $ease,
                    transform 500ms $ease;
      }

      // Menu
      &:nth-child(1){
        transform: translate(0, 0);
        opacity: 1;
      }

      // Fermer
      &:nth-child(2){
        transform: translate(0, 100%);
        opacity: 0;

        position: absolute;
        top: 0; left: 0;
      }
    }
  }

  .square{
    .Icon{
      &:nth-child(1){
        
      }

      &:nth-child(2){
        display: none;
      }
    }
  }
}
