.Plan{
  position: relative;
  min-height: 400px;
  padding: 0;

  .Banner{
    margin: 0;

    @include responsive($tabletSmall, max){
      @include cover();
    }

    &:before{
      @include responsive($tabletSmall, max){
        content: none;
      }
    }

    .visual{
      @include responsive($tabletSmall, max){
        transform: scale(1.15);
      }
    }
  }

  nav, ul{
    @include flex(center, center);
    flex-wrap: wrap;
  }

  nav{
    width: 100%;
    position: absolute;
    bottom: 0; left: 0;
    padding: 0.5em;

    @include responsive($tabletSmall){
      padding: 2em;
    }
  }
  
  ul{
    gap: 0.5em;
    padding: 0.5em;
    box-shadow: 0px 50px 80px rgba($black, 0.1);

    @include responsive($tabletSmall, max){
      padding: 1em;
      width: 95%;
      transform: translate(0, -15%);
    }

    li{
      .Btn{
        margin: 0;

        @media (pointer: fine) {
          &:hover{
            transform: scale(1.025);
          }
        }
      }
    }
  }
}
