.CircleArrows{
  @include flex(center, center);
  border-radius: 50%;
  background: $red;
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 24px;

  @include responsiveMinMax($tabletSmall, $deskXXL){
    width: get-vw(24);
    height: get-vw(24);
  }

  .swiper-navigation &{
    width: 60px;
    height: 60px;
    background: $white;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      width: get-vw(60);
      height: get-vw(60);
    }

    @include noTouchHover() {
      .Icon.--arrow{
        &:nth-child(1){
          transform: translate(-50%, -50%) scale(1);
        }
                  
        &:nth-child(2){
          transform: translate(250%, 0%) scale(0.5);
        }
      }
    }
  }

  .Icon.--arrow{
    body:not(.--animating) &{
      transition: transform 500ms $ease;
    }

    &:nth-child(1){
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-250%, -50%) scale(0.5);
    }

    &:nth-child(2){}

    svg > *{
      stroke: $black;
    }
  }
}