.LinkTransvilles{
  font: 500 14px $main-font;
  @include font-limited(14);

  .Icon.--arrow-external{
    transform: translate(0, -0.1em);

    svg > *{
      stroke: $red;
    }
  }
}
