.Close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  min-width: 45px;
  max-width: 45px;
  background-color: $white;
  border-radius: 50%;
  transition: background-color 550ms $ease,
              transform 550ms $ease;
      
  @include responsiveMinMax($tabletSmall, $deskXXL){
    min-width: get-vw(45);
    max-width: get-vw(45);
  }

  @include noTouchHover() {
    transform: scale(1.15) rotate(-180deg);
  }

  &:before,
  &:after{
    @include content();
    height: 2px;
    width: 12px;
    background: $black;
    border-radius: 20em;
    position: absolute;
    transition: transform 550ms $ease;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      width: get-vw(12);
    }
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg)
  }
}
