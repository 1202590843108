.ServiceGalleryCard{ 
  position: relative;
  display: block;
  width: 100%;
  transition: transform 500ms $ease,
              opacity 500ms $ease;

  > .visual-title{
    @include flex(center, center);
    position: absolute;
    bottom: 0; left: 50%;
    width: 100%;
    max-width: 90%;
    transform: translate(-50%, 50%);
    z-index: 1;
        
    > span{
      font: 700 14px $GT-Ultra;
      @include font-limited(14);
      text-transform: uppercase;
      box-shadow: 0px 0px 10px rgba($black, 0.15);
      padding: 0.5em 1em 0.5em;
      color: $black;
    }
  }

  > .visual__container{
    .swiper:not(.--1) &{
      @include imgRatio(1320, 867);
    }
  }
}
