.SectorFeatures{
  @include responsive($tabletSmall){
    margin-top: -2.5em;
  }

  .inner{
    @include flex(flex-start);
    gap: 1em;
    position: relative;
    z-index: 1;
    max-width: 95%;
    margin: 0 auto;
    text-align: left;
    padding: 2em;
    transform: translate(0, 15%);
  
    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  
    @include responsive($tabletSmall){
      gap: 3em;
      padding: 2em 3em;
      max-width: get-vw(870);
      transform: translate(0, 50%);
    }

    @include responsive($deskXXL){
      max-width: 870px;
    }

    > span{
      @include responsive($tabletSmall){
        max-width: get-vw(235);
      }

      @include responsive($deskXXL){
        max-width: 235px;
      }
    }

    ul{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: get-vw(450);
      }

      @include responsive($deskXXL){
        max-width: 450px;
      }

      @media (pointer: fine) {
        &:hover{
          li {
            button{
              > div,
              > span{
                opacity: 0.3;
                transform: scale(0.8);
              }
  
              &:hover{
                > div,
                > span{
                  opacity: 1;
                  transform: scale(1);
                }

                > div{
                  .CircleArrows{
                    transform: rotate(90deg);

                    .Icon.--arrow{
                      &:nth-child(1){
                        transform: translate(-50%, -50%) scale(1);
                      }
                  
                      &:nth-child(2){
                        transform: translate(100%, 0%) scale(0.5);
                      }
                    }
                  }
                }

                > span{
                  transform: translate(0.2em, 0);
                }
              }
            }
          }
        }
      }

      li{
        > button{
          @include flex(flex-start);
          gap: 0.5em;
          width: 100%;

          @include responsive($tabletSmall, max){
            padding: 0.5em 0;
          }

          // Themes
          @each $slug, $background, $title, $chapo, $content in $services-card-themes {
            &[data-theme="#{$slug}"] {
              .CircleArrows{
                background: $background;
              }
            }
          }

          > div,
          > span{
            transform-origin: 0% 50%;
          }

          > div,
          > span,
          .CircleArrows{
            body:not(.--animating) &{
              transition: opacity 500ms $ease,
                          transform 500ms $ease;
            }
          }

          > span{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            body:not(.--animating) &{
              transition: transform 500ms $ease;
            }
          }
        }
      }
    }
  }
}
