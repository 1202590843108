.NewServiceCard{
  @include flex(flex-start);
  gap: 1.5em;
  will-change: transform, opacity;

  @include responsive($tabletSmall, max){
    flex-direction: column;
    padding: 1.5em;
  }

  @include responsive($tabletSmall){
    padding: 3em;
  }

  .SwipeCardContainer:nth-child(even) &{
    flex-direction: row-reverse;
  }

  @include responsive($tabletSmall){
    justify-content: space-between;
  }

  // Themes
  @each $slug, $background, $color, $button in $new-services-card-themes {
    &[data-theme="#{$slug}"] {
      background-color: $background;

      > .wrapper{
        color: $color;

        .Btn{
          background: $button;
        }
      }
    }
  }

  // Illustration
  > .visual__container{
    @include imgRatio(150, 150);
    max-width: 150px;
    pointer-events: none;

    @include responsive($tabletSmall, max){
      position: absolute;
      top: 0; right: 0;
      opacity: 0.25;
      z-index: 0;
    }
    
    @include responsive($tabletSmall){
      max-width: get-vw(150);
    }

    @include responsive($deskXXL){
      max-width: 150px;
    }
  }

  > .wrapper{
    position: relative;
    z-index: 1;

    .visual__container{
      @include imgRatio(346, 52);
      max-height: 36px;
      margin-top: 0.75em;
      margin-bottom: 2em;
      
      @include responsive($tabletSmall){
        max-height: get-vw(52);
      }

      @include responsive($deskXXL){
        max-height: 52px;
      }

      .visual{
        img{
          width: auto;
          max-height: 100%;
        }
      }
    }

    > p{
      font: 700 20px $main-font;
      @include font-limited(24);
      line-height: 1.4;
    }

    .wswyg--content{
      margin-top: 0.5em;
    }
  }
}