.NewServices{
  overflow: clip;

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .head{
      @include responsive($tabletSmall){
        position: sticky;
        top: 10em;
        max-width: get-vw(510);
      }

      @include responsive($deskXXL){
        max-width: 510px;
      }

      p{
        margin-top: 1.5em;
        filter: opacity(0.6);
      }
    }

    .grid{
      display: grid;
      gap: 1em;
      width: 100%;

      @include responsive($tabletSmall){
        max-width: get-vw(720);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 720px;
      }
    }
  }
}
