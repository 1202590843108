.Page{
  padding-top: 15em;
  padding-bottom: 5em;
  background: #3C4243;
  color: $white;
  min-height: 100dvh;

  .container{
    @include flex(flex-start);
    gap: 4em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > * {
      width: 100%;
    }

    .head{
      @include responsive($tabletSmall){
        position: sticky;
        top: 8.5em;
        max-width: get-vw(500);
      }

      @include responsive($deskXXL){
        max-width: 500px;
      }

      h1{
        hyphens: auto;
        word-break: break-all;
      }
    }
    
    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: get-vw(800);
        margin-left: auto;
      }
      
      @include responsive($deskXXL){
        max-width: 800px;
      }
    }
  }
}