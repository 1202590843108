@import url("../../node_modules/swiper/swiper-bundle.min.css");

.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  // Only for Mobile Swiper
  &.--mobile{
    .swiper-wrapper{
      @include responsive($tabletSmall){
        cursor: initial;
      }
    }

    .swiper-navigation{
      order: 2;
      margin-top: 3em;
      justify-content: center;

      @include responsive($tabletSmall){
        display: none;
      }
    }
  }

  &-wrapper {
    order: 2;
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      body:not(.--animating) &{
        transition: transform 600ms $ease!important;
      }
    }

    .swiper-container.--dragging & {
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: transform 600ms $ease!important;

    .--dragging & {
      transform: scale(0.95)!important;
    }
  }

  &-navigation {
    margin-top: 1.5em;
    order: 3;

    ul{
      @include flex(center, flex-end);
      gap: 1em;

      li{
        > * {
          cursor: pointer;

          body:not(.--animating) &{
            transition: opacity 350ms $ease;
          }

          &.swiper-button-disabled {
            opacity: 0.2;
            pointer-events: none;
          }

          &.prev{
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  &-pagination {
    order: 3;
    position: relative!important;
    margin-top: 20px!important;
    bottom: 0px!important;
    @include flex(center, flex-end);

    > * {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: $base;
      cursor: pointer;

      body:not(.--animating) &{
        transition: all 450ms $ease;
      }

      .--dark & {
        background: $grey-l;
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.--active {
        background: $links-color;
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: $grey-l;

    .--dark & {
      background: $base-d;
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: $base;
      border-radius: 4px;
      cursor: pointer;

      .--dark & {
        background: $grey-l;
      }
    }
  }
}
