.visual {
    @include cover();
    pointer-events: none;
    will-change: transform;
    
    &[data-prllxfrom],
    &[data-prllxto]{
      transform: scale(1.02);
      transform-origin: center;
    }

    video {
      z-index: 1;
      @include cover();
      object-fit: cover;
    }

    img {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;

      &.--plchldr {
        @include cover();
        z-index: 0;
        -webkit-filter: blur(30px);
        filter: blur(30px);
        transform: scale(1.2);
      }
    }

    &__container {
      position: relative;
      overflow: hidden;
      width: 100%;

      &:before {
        @include content();
      }
    }
}
