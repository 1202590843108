.ServiceCard{
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  padding: 3em 2em 2em;
  will-change: transform;
  
  @include responsive($tabletSmall){
    max-width: get-vw(1000);
    padding: 3em 3.5em 5em;
  }

  @include responsive($deskXXL){
    max-width: 1000px;
  }

  // Themes
  @each $slug, $background, $title, $chapo, $content in $services-card-themes {
    &[data-theme="#{$slug}"] {
      background: $background;
      
      .tag{ 
        background-color: $chapo; 
      }

      .head {
        h3 {
          color: $title;
        }

        p{
          color: $chapo;
        }
      }

      .wswyg--content{ 
        color: $content;
      }
    }
  }

  // Theme basics
  background: #E1C1E8;
  color: $white;
  .tag{ background-color: #793189; }
  .head h3,
  .wswyg--content{ color: #62256F; }
  .head p{ color: #B477C1; }


  .logo{
    @include imgRatio(210, 92);
    width: 100%;
    margin-bottom: 1em;
    max-width: 210px;
    position: relative;
  
    @include responsive($tabletSmall){
      max-width: get-vw(210);
    }

    @include responsive($deskXXL){
      max-width: 210px;
    }
  
    .tag{
      position: absolute;
      z-index: 1;
      top: 0; left: 0;
      transform: translate(-20%, -50%) rotate(-5.85deg);
    }
  
    .visual__container{
      @include cover();
  
      .visual{
        img{
          width: auto;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .head{
      position: relative;
  
      @include responsive($tabletSmall){
        max-width: get-vw(320);
      }

      @include responsive($deskXXL){
        max-width: 320px;
      }
  
      h3{
        font: 700 20px $GT-Ultra;
        @include font-limited(40);
        line-height: 0.9;
      }
  
      p{
        font: 700 20px $main-font;
        @include font-limited(24);
        line-height: 1.1;
        margin-top: 1em;
      }
    }
  
    .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 1em;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(480);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 480px;
      }
    }
  }
}
