.Accordion{
  &:not(:first-child) {
    padding-top: 1em;
  }
  
  &.--active{
    > .inner{
      .head{
        > .circle{
          background-color: $purple-light;
          transform: rotate(-90deg) scale(0.8);

          &:before,
          &:after{
            background-color: $purple-dark;
          }

          &:before{
            opacity: 0;
          }
        }
      }
    }
  }

  > .inner{
    position: relative;
    
    body:not(.--animating) &{
      transition: transform 500ms $ease,
                  opacity 500ms $ease;
    }

    .head{
      @include flex(flex-start);
      padding: 1.4em 1.5em;
      width: 100%;
  
      > span{
        font: 700 18px $main-font;
        @include font-limited(24);
        line-height: 1.4;
        color: $purple-dark;
        padding-right: 1em;
        flex: 1;

        @include responsive($tabletSmall){
          padding-top: 0.5em;
        }
  
        > strong{
          font-weight: 1000;
        }
      }
  
      > .circle{
        @include flex(center, center);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $purple-dark;

        body:not(.--animating) &{
          transition: transform 500ms $ease,
                      background-color 500ms $ease;
        }

        @include responsive($tabletSmall){
          height: get-vw(40);
          width: get-vw(40);
        }

        @include responsive($deskXXL){
          max-height: 60px;
          max-width: 60px;
        }
  
        &:before,
        &:after{
          @include content();
          position: absolute;
          height: 2px;
          width: 16px;
          background-color: $white;
          border-radius: 20em;
          
          body:not(.--animating) &{
            transition: opacity 500ms $ease,
                        background-color 500ms $ease;
          }
        }
  
        &:before{
        }
  
        &:after{
          transform: rotate(-90deg);
        }
      }
    }
  
    .content{
      max-height: 0;
      overflow: hidden;
      max-width: 60em;

      body:not(.--animating) &{
        transition: max-height 450ms $ease;
      }
  
      .inner{
        padding: 0 1.65em 2em;
      }
    }
  }
}
