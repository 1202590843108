.ServiceVisualZoomPopup{
  position: fixed;
  inset: 0;
  z-index: 100;
  background: rgba($black, 0.8);
  overflow: scroll;
  padding: 10em;
  transition: opacity 550ms $ease;

  body:not(.--show-service-popup) &{
    opacity: 0;
    pointer-events: none;
  }

  .inner{
    @include flex(center, center);
    height: 100%;
    width: 100%;
    min-width: 800px;

    img{
      width: 100%;
      // max-width: 90%;
      height: auto;
      
      @include responsive($tabletSmall){
        max-width: get-vw(900);
      }

      @include responsive($deskXXL){
        max-width: 900px;
      }
    }
  }

  .Close{
    position: fixed;
    top: 2em;
    right: 2em;
  }
}
