.App {
  width: 100%;
  min-height: 100vh;
  background: $white;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  html.--dark & {
    background: $base;
    color: $white;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 1.5em;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }
}

section {
  padding: 80px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}
