h1, h2, h3, h4, h5, h6{
	text-transform: uppercase;
	letter-spacing: -0.02em;

	&.--small{
		font: 700 26px $GT-Ultra;
		@include font-limited(45);
		line-height: 0.9;
	}

	&.--mid{
		font: 700 30px $GT-Ultra;
		@include font-limited(56);
		line-height: 0.9;
	}
}

h1 {
	font: 700 50px $GT-Ultra;
	@include font-limited(85);
	line-height: 0.9;
	text-transform: uppercase;
}

h2{
	font: 700 40px $GT-Ultra;
	@include font-limited(66);
	line-height: 0.9;
}

// h2 {
//    font: 400 32px $main-font;
//    margin-bottom: 1em;

//    @include responsive($tabletSmall){
//      @include font-vw(32);
//    }
// }

// h3 {
//    font: 400 24px $main-font;
//    margin-bottom: 1em;

//    @include responsive($tabletSmall){
//      @include font-vw(24);
//    }
// }

// h4 {
//    font: 400 22px $main-font;
//    margin-bottom: 1em;

//    @include responsive($tabletSmall){
//      @include font-vw(22);
//    }
// }

// h5 {
//    font: 400 20px $main-font;
//    margin-bottom: 1em;

//    @include responsive($tabletSmall){
//      @include font-vw(20);
//    }
// }

// h6 {
//    font: 400 18px $main-font;
//    margin-bottom: 1em;

//    @include responsive($tabletSmall){
//      @include font-vw(18);
//    }
// }

// sup {
// 	vertical-align: top;
// 	filter: opacity(0.95);

// 	@include responsive($tabletSmall, max) {
// 		font-size: 50px;
// 	}

// 	@include responsive($tabletSmall) {
// 		@include font-vw(50);
// 	}
// }

// .subtitle {
// 	filter: opacity(0.5);
// 	letter-spacing: -0.03em;
// 	font-size: 20px;

// 	@include responsive($tabletSmall) {
// 		@include font-vw(20);
// 	}
// }

.ribbon{
	font: 700 20px $GT-Ultra;
	@include font-limited(24);
	line-height: 1;
	text-transform: uppercase;
	color: $white;
	background-color: #530B28;
	padding: 0.3em 0.6em;
	display: inline-block;
}

.tag{
	font: 700 14px $GT-Ultra;
	@include font-limited(14);
	text-transform: uppercase;
	color: $white;
	background: $black;
	border-radius: 20em;
	padding: 0.9em 1.2em;
}

.chapo{
	font: 500 20px $main-font;
	@include font-limited(27);
	line-height: 1.4;
	color: $black;
}

strong {
	font-weight: 500;
}

p {
	&.no__results {
		text-align: center;
		opacity: 0.2;
	}
}

.wswyg--content {
	text-align: left;

	* {
		color: inherit;
	}

	> * + * {
		margin-top: 1em;
	}

	h2{
		font: 700 22px $main-font;
		@include font-limited(28);
	}

	h3{
		font: 700 20px $main-font;
		@include font-limited(26);
	}

	h4{
		font: 700 18px $main-font;
		@include font-limited(24);
	}

	h5{
		font: 700 16px $main-font;
		@include font-limited(22);
	}

	h6{
		font: 700 14px $main-font;
		@include font-limited(20);
	}

	h2, 
	h3, 
	h4, 
	h5, 
	h6{
		text-transform: initial;
		letter-spacing: -0.02em;
		line-height: 1.3;
	}

	ul,
	ol {
		padding-left: 1em;

		code {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style-type: decimal;
	}

	figure {
		margin: 1em auto;
		overflow: hidden;

		@include responsive($tabletSmall) {
			margin: space(1) auto;
		}

		&:last-child {
			margin-bottom: 0;
		}

		img {
			display: block;
		}
	}

	blockquote {
		padding-left: 1em;
		border-left: 2px solid $grey;

		p {
			@include font-limited(28);
			line-height: 1.4;
		}
	}

	a {
		font-weight: 700;
		text-decoration: underline;
		// color: $links-color;
	}

	table{
		font-size: 0.75em;

		td, th{
			padding: 1em;
			border: 1px solid rgba($grey-l, 0.25);

			*{
				font: inherit;
			}

			strong{
				font-weight: 700;
			}
		}
	}
}
