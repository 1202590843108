$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  body:not(.--animating) &{
    transition: background-color 500ms $ease,
                box-shadow 500ms $ease,
                transform 500ms $ease;
  }

  .container{
    flex-wrap: wrap;
    padding-left: 1em;
    padding-right: 1em;
  }

  // When header is hiding
  &.--hide{
    transform: translate(0, -100%);
    box-shadow: 0px 0px 10px rgba($black, 0);
  }

  // When header is scrolling
  &.--scroll{
    &:not(.--hide) {
      transform: none;
      background: $red;
      box-shadow: 0px 0px 10px rgba($black, 0.15);

      .Header__banner{
        padding-top: 0.45em;
        padding-bottom: 0.25em;
      }

      .Header__main{
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }
  }

  // When the service visual zoom popup is activated
  body.--show-service-popup &{
    &.--hide,
    &.--scroll{
      transform: translate(0, -100%);
      box-shadow: 0px 0px 10px rgba($black, 0);
    }
  }

  &__banner,
  &__main{
    position: relative;

    body:not(.--animating) &{
      transition: padding 500ms $ease;
    }
  }

  &__banner{
    background: $white;
    padding-top: 0.75em;
    padding-bottom: 0.5em;
    pointer-events: all;
    z-index: 0;

    .container{
      @include flex(center, center);
    }
  }

  &__main{
    padding-top: 1em;
    padding-bottom: 1em;
    z-index: 1;

    .container{
      @include flex(center, space-between);

      .Brand{
        transition: none;
        
        body:not(.--animating).--show-menu &{
          pointer-events: none;
          opacity: 0.1;
          transition: opacity 250ms $ease;
        }

        .MainLogo{
          svg {
            g.baseline > *,
            .trans,
            .villes{
              fill: $white;
            }
          }
        }
      }
    }
  }

  > .ov{
    @include cover(fixed);
    background: rgba($black, 0.8);
    z-index: 0;
    pointer-events: all;
    opacity: 0;

    body:not(.--show-menu) &{
      pointer-events: none;
    }

    @include responsive($menuBreakpoint){
      display: none;
    }
  }
}
