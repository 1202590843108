.NotFound{
  background: #1C1F20;
  color: $white;

  .container,
  .container > *{
    @include flex(center, center);
    flex-direction: column;
  }
  
  .container{
    text-align: center;
    height: 100dvh;

    h1{
      font: 700 100px $GT-Ultra;
      @include font-limited(150);
      word-break: break-all;
      hyphens: auto;
    }
  }
}