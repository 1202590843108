/* -----
Paths
----- */

$base-images: "../images/";
$base-fonts: "../fonts/";

/* -----
Colors
----- */
$white: #ffffff;
$black: #000000;
$grey: #999999;
$grey-l: #eeeeee;
$gold: #af904d;
$base: #1a1a1a;
$base-d: #141414;
$links-color: $gold;
$error-color: #dc3838;
$valid-color: #5da854;

/* -----
Custom Colors
----- */
$red: #D7351F;
$red-flex-sesame: #D5381D;
$grey-light: #8D8E8E;
$grey-express: #47474A;
$orange: #F18815;
$ocre: #F4C245;
$yellow: #FFCD00;
$yellow-fletad: #FCBF37;
$green-light: #94C356;
$blue-services: #2C296C; 
$blue-light: #75C9DA;
$cyan: #4DBCC5;
$blue-dark: #16608A;
$blue-duck: #0B707D;
$purple: #78368C;
$purple-light: #C292E4;
$purple-dark: #42073C;
$pink: #E71984;
$mauve: #DD4B95;


/* -----
Colors Themes
----- */

// Themes in : single-app_city (BlockCityChangements/service-card.twig)
$services-card-themes:
  // Slug                // Background     // Title       // Chapo        // Content
  'grey_light'          #A6A9AB          #3C4243      #838282       #3C4243,
  'grey_transvilles'    #838082          #1C1F20      #5B5A59       #FFFFFF,
  'red_light'           #EC6C7E          #530B28      #D0424A       #FFFFFF,
  'orange'              #EF8050          #522510      #D64A21       #FFFFFF,
  'ocre'                #FFE171          #6F432C      #D5A344       #6F432C,
  'yellow'              #FFDF43          #4F3309      #DC9E1D       #4F3309,
  'green_light'         #A7D2AE          #003C27      #80AE72       #003C27,
  'blue_light'          #A4D6D4          #1B1B3A      #4DBCC5       #1B1B3A,
  'blue_dark'           #5F92B0          #1E274A      #2F628A       #FFFFFF,
  'blue_duck'           #88CBC9          #003036      #00AAA3       #FFFFFF,
  'purple'              #BBA2CD          #392864      #895EA3       #793289,
  'pink'                #F092BC          #40163F      #EB619E       #FFFFFF,
  'flextad'             #FCBF37          #2C296C      #2C296C       #2C296C,
  'flexpro'             #6EC1ED          #2C296C      #2C296C       #2C296C,
  'flexo'               #DD4B95          #2C296C      #2C296C       #2C296C,
  'flexsesame'          #D5381D          #FFFFFF      #2C296C       #2C296C,
;

// Themes in : single-app_city (new-service-card.twig)
$new-services-card-themes: 
// Slug                  // Background     // Color       // Button
'flextad'               #FCBF37          #2C296C      #2C296C,
'flexpro'               #6EC1ED          #2C296C      #2C296C,
'flexo'                 #DD4B95          #2C296C      #2C296C,
'flexsesame'            #D5381D          #FFFFFF      #2C296C
;

// Themes in : page-discover-network.twig (navigation-service-card.twig)
$services-navigation-themes: 
  // Slug                // Subtitle       // Background  // Circle
  'lianes'              #793189          #793189      #ED7D31,
  'flextad'             #FCBF37          #FCBF37      #FCBF37,
  'flexpro'             #6EC1ED          #6EC1ED      #6EC1ED,
  'flexo'               #DD4B95          #DD4B95      #DD4B95,
  'flexsesame'          #D5381D          #D5381D      #D5381D,
  'stronger-network'    #5E93B0          #5E93B0      #5E93B0
;

// Themes in : page-discover-network.twig (sections BlockService)
$services-themes: 
  // Slug             // Background    // Color         // Button
  'lianes'           #F3F3F3        #793189        #ED7D31,
  'flextad'          #FCBF37        #2C296C        #2C296C,
  'flexpro'          #6EC1ED        #2C296C        #2C296C,
  'flexo'            #DD4B95        #2C296C        #2C296C,
  'flexsesame'       #D5381D        $white           #2C296C
;

// Themes in : _btn.scss
$buttons-themes: 
  // Slug            // Background     // Color         // Circle        // Icon
  'red'             $red               $white          #0b0706         $white,
  'purple'          #530B28          $white          #b84372         $white,
  'purple-d'        #42073C          $white          #C292E4         $white,
  'cyan'            $cyan              $white          #a5d6d5         $white,
  'white'           $white             $black          $red              $black,
  'green'           #95C356         #003C27        #b1e36a         #003C27
;

/* -----
Fonts
----- */

$main-font: 'Poppins', sans-serif;
$GT-Ultra: 'GT-Ultra', sans-serif;

/* -----
Grid
----- */

$base-vw: 1440;
$base-grid: 24;
$browser-context: 20;

/* -----
CSS Easings
----- */

$transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);

// GSAP Easings
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860); // wip
$ExpoEaseIn: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ExpoEaseInOut: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;
