.MainLogo{
  @include imgRatio(186, 49);
  min-width: 170px;
  max-width: 170px;
  
  @include responsive($tabletSmall){
    min-width: get-vw(186);
    max-width: get-vw(186);
  }

  @include responsive($deskXXL){
    min-width: 186px;
    max-width: 186px;
  }

  svg {
    @include cover();

    g.baseline > *{
      fill: #747172;
    }

    .trans{
      fill: #747172;
    }

    .villes{
      fill: $red;
    }
  }
}