.FaqQuestions{
  padding-top: 0;
  background: $purple-light;

  .grid{
    @media (pointer: fine) {
      &:hover{
        .Accordion{
          &:hover{
            .inner{
              opacity: 1;
              transform: scale(1);
            }
          }

          .inner{
            opacity: 0.5;
            transform: scale(0.97);
          }
        }
      }
    }
  }
}
