.PageHeader{
  position: relative;
  padding-top: 12.5em;
  padding-bottom: 5em;

  .container{
    z-index: 1;
    text-align: center;

    > span.subtitle{
      font: 700 22px $GT-Ultra;
      @include font-limited(34);
      letter-spacing: -0.02em;
      text-transform: uppercase;
    }
  }

  .visual__container.--network{
    @include cover();
    z-index: 0;
    pointer-events: none;
  }
}
