.NetworkQuestion{
  background: #BCA3CE;
  color: $purple-dark;
  position: relative;

  .container{
    @include flex(center, center);
    flex-direction: column;
    text-align: center;
    z-index: 1;

    h2{
      margin: 0 auto;
      color: #392864;

      @include responsive($tabletSmall){
        max-width: get-vw(540);
      }

      @include responsive($deskXXL){
        max-width: 540px;
      }

      strong{
        color: $white;
      }
    }

    .content{
      .wswyg--content{
        text-align: center;
        margin: 1em auto 0;

        @include responsive($tabletSmall){
          max-width: get-vw(540);
        }

        @include responsive($deskXXL){
          max-width: 540px;
        }
      }
    }
  }

  > .visual__container{
    @include cover();
    z-index: 0;
    pointer-events: none;
  }
}
