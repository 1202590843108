.Footer {
   background: $base;

   &__banner{
      padding: 2em 0;

      .container{
         @include flex(center, space-between);
         flex-wrap: wrap;
         gap: 1em;

         .Link{
            margin-top: 1.5em;
         }
      }
   }

   &__main{
      background: $red;
      color: $white;

      padding-top: 5em;
      padding-bottom: 1em;

      > .container{
         @include flex();
         flex-direction: column;
      }

      // General FooterNavBlock
      nav{
         > span{
            font: 700 14px $GT-Ultra;
            @include font-limited(14);
            text-transform: uppercase;
            letter-spacing: 0;
            color: #530B28;
         }
      }

      .main,
      .secondary{
         @include flex(flex-start);
         flex-wrap: wrap;
      }

      .main{
         justify-content: space-between;

         @include responsive($tabletSmall, max){
           gap: 2em;
         }

         .NewNetwork{
            @include responsive($tabletSmall, max){
              flex: 1 0 100%;
              max-width: 100%;
            }
         }

         nav{
            max-width: 260px;
            font-size: 18px;
            @include font-limited(18);

            &:nth-child(2){
               @include responsive($tabletSmall){
                  margin-left: 4.5em;
               }
            }

            @include responsive($tabletSmall){
               margin-top: -0.5em;
            }
         }
      }

      .secondary{
         margin-top: 5em;
         justify-content: center;
         font: 400 14px $main-font;
         @include font-limited(14);

         nav{
            ul{
               @include flex(center);
               flex-wrap: wrap;
               gap: 0.25em;

               li{
                  &:not(:last-child){
                     &:after{
                        content: '-';
                        display: inline-block;
                        margin-left: 0.25em;                    
                     }
                  }
               }
            }
         }
      }
   }
}
