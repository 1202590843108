.Nav {
  body:not(.--animating) &{
    pointer-events: all;
  }

  @include responsive($menuBreakpoint, max) {
    position: fixed;
    bottom: 0; left: 50%;
    transform: translate(-50%, 0);
    // top: 4.2em;
    padding: 1em;
    // right: 0.5em;
    height: auto;
    max-height: 100%;
    width: auto;
    // max-width: 80%;
    z-index: 10;
    // border-radius: 20px;
    overflow-y: auto;
  }

  @include responsive($tabletSmall, max){
    left: 0;
    transform: none;
    width: 100%;
  }

  body:not(.--show-menu) & {
    @include responsive($menuBreakpoint, max) {
      display: none;
    }
  }

  ul {
    @include flex(center);
    gap: 2em;

    @include responsive($menuBreakpoint, max) {
      background: $white;
      color: $black;
      border-radius: 20px;

      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 2em;
      gap: 1em;
    }

    li {
      .Btn{
        margin-top: 0;
        background: #530B28;
        
        @include responsive($mobile, max){
          font-size: 3.25vw;          
        }

        @media (pointer: fine) {
          &:hover{
            transform: scale(1.05);
          }
        }
      }

      .Link{
        font-weight: 500;
        @include font-limited(18);

        @include responsive($mobile, max){
          font-size: 5vw;
        }

        body.--show-menu &{
          color: $black;

          span{
            background-image: linear-gradient($black, $black);
          }
        }

        &.--active{
          cursor: not-allowed;
        }
      }
    }
  }
}
