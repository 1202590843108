.Service{
  padding-top: 10em;
  overflow: clip;

  // Themes
  @each $slug, $background, $color, $button in $services-themes {
    &[data-theme="#{$slug}"] {
      background-color: $background;
      color: $color;

      .wrapper{
        .head{
          h2{
            color: $color;
          }
        }

        .main{
          .wswyg--content{
            color: $color;

            h3{
              &:before{
                background-color: $color;
              }
            }          
          }
        }
      }

      .Btn{
        background-color: $button;
        color: $white;
      }
    }
  }

  &[data-theme="lianes"]{
    .wrapper .head .visuals .visual__container.--logo{
      max-height: 65px; 

      @include responsive($tabletSmall){
        max-height: get-vw(90);
      }

      @include responsive($deskXXL){
        max-height: 90px;
      }
    }

    .Btn{
      .circle{
        &:before{
          background-color: #f0975b;
        }
      }
    }
  }

  .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2em;
    }

    > *{
      width: 100%;
    }

    .head{
      @include responsive($tabletSmall){
        position: sticky;
        top: 10em;
        max-width: get-vw(540);
      }

      @include responsive($deskXXL){
        max-width: 540px;
      }

      h2{
        color: #2C296C;
      }

      .visuals{
        @include flex(center);
        gap: 1em;
        margin-bottom: 1em;
        width: 100%;

        @include responsive($tabletSmall){
          position: absolute;
          bottom: 100%;
        }

        .visual__container{
          .visual{
            img{
              width: auto;
              max-height: 100%;
            }
          }

          &.--illustration{
            @include imgRatio(96, 96);
            max-width: 50px;

            @include responsive($tabletSmall){
              max-width: get-vw(96);
            }

            @include responsive($deskXXL){
              max-width: 96px;
            }
          }

          &.--logo{
            @include imgRatio(180, 60);
            max-height: 35px; 

            @include responsive($tabletSmall){
              max-height: get-vw(60);
            }

            @include responsive($deskXXL){
              max-height: 60px;
            }
          }
        }
      }
    }

    .content{
      @include responsive($tabletSmall){
        max-width: get-vw(620);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 620px;
      }

      .wswyg--content{
        color: #2C296C;

        h3{
          &:before{
            content: '';
            display: inline-block;
            background-color: #2C296C;
            -webkit-mask: url("data:image/svg+xml;charset=utf-8,%3Csvg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M25.6568 0.930858C25.6568 0.930858 10.7113 -0.60621 3.2408 8.28185C-4.23193 17.1699 3.2408 23.8769 5.57491 25.5912C7.90902 27.3033 28.925 31.6722 25.6568 0.933101' fill='currentColor'/%3E %3C/svg%3E ");
            mask-repeat: no-repeat;
            mask-size: 100% 100%;
            height: 1em; 
            width: 1em;
            margin-right: 0.5em;
            margin-bottom: -0.2em;
          }
        }
      }
    }
  }

  .swiper.--mobile{
    margin-top: 5em;

    // Only for slides > 1
    &:not(.--1){
      .swiper-slide{
        @include responsive($tabletSmall){
          flex: 1 0 calc(25% - 2em);
          max-width: calc(25% - 2em);
        }
      }
    }

    // Only for slide === 1
    &.--1{
      flex: 1 0 100%;
      max-width: 100%;
    }
    
    .swiper-wrapper{
      @include responsive($tabletSmall){
        @include flex(stretch, center);
        flex-wrap: wrap;
        gap: 4em 2em;
      }

      @media (pointer: fine) {
        &:hover{
          .swiper-slide{
            .ServiceGalleryCard{
              opacity: 0.85;
              transform: scale(0.85);

              &:hover{
                opacity: 1;
                transform: scale(1);
              }
            }
          }
        }
      } 
    }
  }
}
