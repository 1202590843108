.PageHeaderDiscoverNetwork{
  padding-bottom: 0;
  position: relative;
  z-index: 1;

  .container{
    h1{
      color: $white;

      @include responsive($tabletSmall){
        max-width: get-vw(840);
        margin: 0 auto;
      }

      @include responsive($deskXXL){
        max-width: 840px;
      }

      strong{
        color: #530B28;
      }
    }
  }

  .container.--oh{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
      padding-bottom: 3em;
    }
    
    @include responsive($tabletSmall){
      margin-top: -3em;
      transform: translate(0, 50%);
    }

    .swiper{
      .swiper-wrapper{
        @media (pointer: fine) {
          &:hover{
            .swiper-slide {
              .NavigationServiceCard {
                transform: scale(0.9);
  
                &:hover{
                  transform: scale(1);

                  .CircleArrows{
                    .Icon.--arrow{
                      &:nth-child(1){
                        transform: translate(-50%, -50%) scale(1);
                      }
                  
                      &:nth-child(2){
                        transform: translate(100%, 0%) scale(0.5);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .swiper-slide{
        min-height: 244px;
        width: 235px;
      
        @include responsiveMinMax($tabletSmall, $deskXXL){
          min-height: get-vw(244);
          width: get-vw(205); 
        }
      }
    }
  }

  & + .Banner{
    margin: 0;
  }
}
