.SearchCommune{
  position: relative;

  .container{
    @include flex(center, center);
    flex-direction: column;
    z-index: 1;

    @include responsive($tabletSmall){
      margin: 0 auto;
    }

    h2{
      text-align: center;
      color: $white;
      margin-bottom: 0.5em;

      @include responsive($tabletSmall){
        max-width: get-vw(830);
      }

      @include responsive($deskXXL){
        max-width: 830px;
      }
    }
  }

  > .visual__container{
    z-index: 0;
    pointer-events: none;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba($black, 0.3) 0%, rgba($black, 0) 100%);
    }

    .visual{
      transform: scale(1.05);
    }
  }
}
