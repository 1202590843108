.PageHeaderFAQ{
  background: $purple-light;
  position: relative;
  padding-bottom: 25em;
  margin-bottom: -20em;

  .container{
    z-index: 1;

    span.subtitle{
      color: $purple-dark;
    }

    h1{
      color: $white;
      
      @include responsive($tabletSmall){
        max-width: get-vw(700);
        margin: 0 auto;
      }

      @include responsive($deskXXL){
        max-width: 700px;
      }
    }
  }
}
