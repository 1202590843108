.Accessibility{
  background: #A7D2AE;
  color: #003C27;

  .container{
    @include flex(flex-start, space-between);
    gap: 1em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    h2{
      max-width: 640px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(640);
      }
    }
    
    .content{
      max-width: 570px;
  
      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(570);
      }

      .chapo{
        margin-bottom: 1em;
      }
    }
  }

  & + .Banner{
    margin-top: 0;
  }
}
