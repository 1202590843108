.Btn {
  cursor: pointer;
  font: 500 12px $main-font;
  @include font-limited(15);

  display: inline-flex;
  align-items: center;
  gap: 0.7em;
  background: #2C296C;
  color: $white;
  border-radius: 14px;
  padding: 1.1em 1.5em 1em;
  margin-top: 1.5em;
  
  body:not(.--animating) &{
    transition: filter 450ms $ease,
                transform 450ms $ease;
  }

  &.--hidden{
    display: none;
  }

  /*
  * Themes
  */

  @each $slug, $background, $color, $circle, $icon in $buttons-themes {
    &.--#{$slug}{
      background-color: $background;

      .circle{
        &:before{
          background-color: $circle;
        }

        .Icon.--arrow svg > *,
        .Icon.--arrow-download svg > *{
          stroke: $icon;
        }
      }
      
      span{
        color: $color;
      }
    }
  }

  /*
  * Hover
  */

  @media (pointer: fine) {
    &:hover{
      transform: scale(1.1);
      filter: brightness(1.015);

      > .circle{
        &:before{
          transform: scale(1.5);
          opacity: 1;
        }

        .arrows{
          transform: rotate(-7.5deg) scale(0.95);

          .Icon.--arrow{
            &:nth-child(1){
              transform: translate(-50%, -50%) scale(1);
              opacity: 1;
            }

            &:nth-child(2){
              transform: translate(100%, 0%) scale(0.1);
              opacity: 0;
            }
          }
        }

        .Icon.--arrow-download,
        .Icon.--arrow-external{
          transform: rotate(4deg) scale(0.9);
        }
      }
    }
  }

  > span{
    color: $white;
  }

  > .circle{
    @include flex(center, center);
    position: relative;
    height: 18px;
    width: 18px;
    transform: translate(0, -0.1em); 

    @include responsive($tabletSmall){
      height: get-vw(18);
      width: get-vw(18);
    }

    @include responsive($deskXXL){
      height: 18px;
      width: 18px;
    }

    &:before{
      @include content();
      @include cover();
      background: #8885d4;
      z-index: 0;
      transform: scale(1);
      opacity: 0;
      border-radius: 50%;   
      
      body:not(.--animating) &{
        transition: opacity 450ms $ease,
                    transform 450ms $ease;
      }
    }

    .arrows,
    .Icon{
      pointer-events: none;
      
      body:not(.--animating) &{
        transition: opacity 450ms $ease,
                    transform 450ms $ease;
      }
    }

    .arrows{
      .Icon.--arrow{
        &:nth-child(1){
          position: absolute;
          top: 50%; left: 50%;
          opacity: 0;
          transform: translate(-100%, -50%) scale(0.1);
        }
      }
    }
  }

}
