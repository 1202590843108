.NewNetwork{
  @include responsive($tabletSmall){
    max-width: get-vw(410);
  }

  @include responsive($deskXXL){
    max-width: 410px;
  }

  .PageHeaderHome &{
    @include responsive($tabletSmall){
      max-width: get-vw(500);
    }

    @include responsive($deskXXL){
      max-width: 500px;
    }
  }

  > span{
    font: 700 30px $GT-Ultra;
    line-height: 1;
    text-transform: uppercase;
    color: #530B28;
  }

  > h2{
    font-size: 40px;
    @include font-limited(60);
  }

  > .ribbon{
    transform: rotate(-1.5deg);

    .PageHeaderHome &{
      @include font-limited(28);
    }
  }

  > ul{
    margin-top: 1.75em;

    li + li{
      margin-top: 0.3em;
    }

    li{
      @include flex(flex-start);
      font: 700 26px $main-font;
      @include font-limited(26);
      line-height: 1;
      gap: 0.35em;

      &:before{
        @include content();
        display: inline-block;
        height: 26px;
        width: 26px;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.2408 7.45251C10.7113 -1.43556 25.6568 0.101512 25.6568 0.101512V0.103756C28.925 30.8429 7.90902 26.474 5.57491 24.7619C3.2408 23.0476 -4.23193 16.3406 3.2408 7.45251ZM10.8567 7.64795V11.5119H6.88062V15.9907H10.8567V19.8839H15.6794V15.9907H19.6846V11.5119H15.6794V7.64795H10.8567Z' fill='white'/%3E%3C/svg%3E%0A");
        background-size: 100%;
        background-repeat: no-repeat;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          height: get-vw(26);
          width: get-vw(26);
        }
      }

      span{
        flex: 1;
      }
    }
  }
}
