.PageHeaderHome{
  background: $red;
  background: #D7351F;
  color: $white;
  padding-bottom: 0;

  & + section{
    padding-top: 10em;
  }

  .container{
    text-align: left;

    .wrapper {
      @include flex(flex-start);
      flex-direction: column;

      > .visual__container{
        @include imgRatio(250, 211);
        width: 100%;
        
        @include responsive($tabletSmall){
          position: absolute;
          bottom: 0; right: 0;
          max-width: get-vw(720);
        }

        @include responsive($deskXXL){
          max-width: 720px;
        }
      }
    }

    > div#quels-changements-dans-ma-commune{
      left: 50%;

      @include responsive($tabletSmall, max){
        position: absolute;
        width: 100%;
        max-width: 95%;
        bottom: 0;
        transform: translate(-50%, 50%) !important;
      }

      @include responsive($tabletSmall){
        
      }
    }
  }
}
