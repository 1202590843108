.NavigationServiceCard{
  height: 100%;
  width: 100%;

  body:not(.--animating) &{
    transition: opacity 500ms $ease,
                transform 500ms $ease;
  }

  @include noTouchHover() {
    .inner{
      &:before{
        transform: scaleY(1.25) scaleX(1.25);
      }

      .subtitle,
      .title{
        color: $white;
      }

      .CircleArrows{
        background-color: $white;
        transform: rotate(90deg);
      }
    }
  }

  // Themes
  @each $slug, $subtitle, $background, $circle in $services-navigation-themes {
    &[data-theme="#{$slug}"] {
      .inner{
        &:before{
          background-color: $background;
        }

        .subtitle{
          color: $subtitle;
        }
        
        .CircleArrows{
          background-color: $circle;
        }
      }
    }
  }
  
  .inner{
    @include flex(center, center);
    flex-direction: column;
    text-align: center;
    background-color: $white;
    height: 100%;
    width: 100%;
    padding: 1.5em 1em;
    position: relative;
    overflow: hidden;

    &:before{
      @include content();
      @include cover();
      border-radius: 0 0 10em 10em;
      background: $red;
      z-index: 0;
      pointer-events: none;
      transform: scaleY(0);
      transform-origin: 50% 0%;

      body:not(.--animating) &{
        transition: transform 500ms $ease;
      }
    }

    > *{
      position: relative;
      z-index: 1;
    }

    .subtitle,
    .title{
      margin-bottom: 2em;

      body:not(.--animating) &{
        transition: color 500ms $ease;
      }
    }

    .subtitle{
      font: 700 14px $GT-Ultra;
      @include font-limited(14);
      text-transform: uppercase;
    }

    .title{
      font: 700 20px $main-font;
      @include font-limited(20);
      line-height: 1;
      color: #2C296C;

      strong{
        font: inherit;
      }
    }

    .CircleArrows{
      margin-top: auto;

      body:not(.--animating) &{
        transition: transform 500ms $ease,
                    background-color 500ms $ease;
      }
    }
  }
}
