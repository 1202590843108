.StrongerNetwork{
  background: #A7D2AE;
  color: #003C27;
  overflow: hidden;
  position: relative;

  .container{
    @include flex(flex-start);
    flex-direction: column;
    gap: 3em;
    z-index: 1;

    .head{
      width: 100%;

      @include responsive($tabletSmall){
        padding-left: 3.5em;        
      }

      .ribbon{
        transform: rotate(-2deg);
        margin-bottom: 1em;
        color: #C5D65E;
        background-color: $white;
      }

      h2{
        max-width: 300px;

        @include responsive($tabletSmall){
          max-width: get-vw(540);
        }

        @include responsive($deskXXL){
          max-width: 540px;
        }

        strong{
          color: $white;
        }
      }

      .visual__container{
        @include imgRatio(200, 121);
        z-index: -1;
        margin-top: 1em;
        max-width: 550px;

        @include responsive($tabletSmall){
          max-width: get-vw(860);
          margin-top: -4.5em;
          margin-left: 6em;
        }

        @include responsive($deskXXL){
          max-width: 860px;
        }
      }
    }

    .content{
      max-width: 620px;
      
      @include responsive($tabletSmall){
        max-width: get-vw(1000);
        margin-left: auto;
        columns: 2;
      }

      @include responsive($tabletSmall){
        max-width: 1000px;
      }
    }
  }

  > .visual__container{
    @include cover();
    z-index: 0;
  }
}
